import React, { useState, useEffect } from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery, useQueryClient } from "react-query";

import Styles from "./Season.module.scss";
import Layout from "../../Components/Layout/Layout";
import GlobalProgress from "../../Components/GlobalProgress/GlobalProgress";
import WorkoutHighTouchRight from "../../Assets/WorkoutHighTouchRight.svg";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

import ChallengeRank from "./ChallengeRank.js";

import Racer from "../../Assets/Racer.js";
import SpeedCage from "../../Assets/SpeedCage";

import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

import ROOT_URL from "../../Config/rootUrl";

const Season = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(
    ["getSeasonProgress"],
    async () => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(`${ROOT_URL}/api/v1/season/progress`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 404) {
        return { message: "No ongoing season found." };
      }

      const responseJson = await response.json();
      if (responseJson?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    }
  );

  const seasonRank = useQuery(
    ["getSeasonRank", data?.id], // Use the ID as part of the query key
    async () => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/season/${data.id}/user-rank`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 404) {
        return { message: "No ranking found." };
      }

      const responseJson = await response.json();
      if (responseJson?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      enabled: !!data?.id, // Only run this query if data.id is available
    }
  );

  const globalProgress = useQuery(["getGlobalProgress"], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/global-progress/read/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        progress: "global-progress",
      }),
    });

    const responseJson = await response.json();
    if (responseJson?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });

  const [timeLeft, setTimeLeft] = useState({});
  const [seasonTimeLeft, setSeasonTimeLeft] = useState({});

  useEffect(() => {
    if (data && !data.message) {
      const ongoingSegment = findOngoingSegment(data.seasonSegments);

      if (ongoingSegment) {
        setTimeLeft(calculateTimeLeft(ongoingSegment.endDate));
        const timer = setInterval(() => {
          const newTimeLeft = calculateTimeLeft(ongoingSegment.endDate);
          setTimeLeft(newTimeLeft);

          if (Object.keys(newTimeLeft).length === 0) {
            clearInterval(timer);
            queryClient.invalidateQueries(["getSeasonProgress"]);
          }
        }, 1000);

        return () => clearInterval(timer);
      }
    }
  }, [data, queryClient]);

  useEffect(() => {
    if (data && data.endDate) {
      const timer = setInterval(() => {
        const newSeasonTimeLeft = calculateTimeLeft(data.endDate);
        setSeasonTimeLeft(newSeasonTimeLeft);

        if (Object.keys(newSeasonTimeLeft).length === 0) {
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [data]);

  function calculateTimeLeft(endDate) {
    if (!endDate) return {};

    const difference = new Date(endDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  function findOngoingSegment(segments) {
    const currentDate = new Date();
    return segments.find((segment) => {
      const startDate = new Date(segment.startDate);
      const endDate = new Date(segment.endDate);
      return currentDate >= startDate && currentDate <= endDate;
    });
  }

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <div>Error loading data. Please try again.</div>;
  if (data?.message === "No ongoing season found.") {
    return (
      <Layout>
        <div className={Styles.pageHeader}>
          {userDetails?.user?.role && userDetails.user.role === "coach" ? (
            <div className={Styles.userRole}>
              <object
                className={Styles.svgAnimation}
                type="image/svg+xml"
                data={WorkoutHighTouchRight}
                aria-label="animation"
              />
              <p>[ExerCube] Coach</p>
            </div>
          ) : null}

          <h1>{data?.username || userDetails?.user?.username}</h1>

          {!!globalProgress?.data && !globalProgress?.data?.error && (
            <GlobalProgress data={globalProgress.data} />
          )}
        </div>
        <div className={Styles.noSeason}>No ongoing season found.</div>
      </Layout>
    );
  }

  const { name, description, flavourImage, seasonSegments } = data;

  // Find the ongoing segment based on the current date
  const ongoingSegment = findOngoingSegment(seasonSegments);

  return (
    <Layout>
      <div className={Styles.pageHeader}>
        {userDetails?.user?.role && userDetails.user.role === "coach" ? (
          <div className={Styles.userRole}>
            <object
              className={Styles.svgAnimation}
              type="image/svg+xml"
              data={WorkoutHighTouchRight}
              aria-label="animation"
            />
            <p>[ExerCube] Coach</p>
          </div>
        ) : null}

        <h1>{data?.username || userDetails?.user?.username}</h1>

        {!!globalProgress?.data && !globalProgress?.data?.error && (
          <GlobalProgress data={globalProgress.data} />
        )}
      </div>

      <div className={Styles.seasonContainer}>
        {flavourImage && <img src={`${ROOT_URL}${flavourImage}`} alt="" />}

        <div className={Styles.seasonOverlay}>
          {description && (
            <h5>
              <span className={Styles.teal}>{description}</span>
            </h5>
          )}
          {name && (
            <h2>
              <span className={Styles.purple}>{name}</span>
            </h2>
          )}

          {ongoingSegment?.seasonChallenges?.length > 0 && (
            <>
              <div className={Styles.challengeContainer}>
                {/* <h3>Challenges</h3> */}
                {ongoingSegment?.seasonChallenges?.map((challenge) => (
                  <div key={challenge.id} className={Styles.challenge}>
                    <div>
                      <h4>{challenge.challengeType}</h4>
                      <p>{challenge.challengeConfig.name}</p>
                    </div>
                    {challenge.seasonChallengeProgresses.some(
                      (progress) => progress.completedAt
                    ) ? (
                      <div className={Styles.challengeCompleted}>
                        <p>
                          <span className={Styles.emoji}>&#10004;</span>
                          <span className={Styles.claimed}>Claimed</span>
                        </p>
                      </div>
                    ) : (
                      <div className={Styles.challengeOpen}>
                        <p>
                          <span className={Styles.emoji}>&#128274;</span>
                          <span className={Styles.expAmount}>+400</span>
                          <span className={Styles.expName}>EXP</span>
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {Object.keys(timeLeft).length > 0 && (
                <div className={Styles.timer}>
                  <span className={Styles.timerText}>Time remaining: </span>
                  <span className={Styles.timerTime}>
                    {timeLeft.days} {timeLeft.days === 1 ? "day" : "days"}{" "}
                    {timeLeft.hours} {timeLeft.hours === 1 ? "hour" : "hours"}{" "}
                    {timeLeft.minutes}{" "}
                    {timeLeft.minutes === 1 ? "minute" : "minutes"}{" "}
                    {timeLeft.seconds}{" "}
                    {timeLeft.seconds === 1 ? "second" : "seconds"}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className={Styles.progress}>
        <h4>Your current progress</h4>

        <div className={Styles.seasonProgress}>
          {/* <div>
            <p>Points</p>
            <p>{data.id}</p>
          </div>
          <div>
            <p>Rank</p>
            <p>{data.seasonSegments[data.seasonSegments.length - 1].id}</p>
          </div>
          <div>
            <p>Badge</p>
            <p>Gold</p>
          </div> */}

          <div className={Styles.points}>
            <h6>Points</h6>
            <div className={Styles.seasonPoints}>
              {data.seasonSegments.reduce((total, segment) => {
                return (
                  total + (segment.seasonSegmentProgresses[0]?.totalPoints || 0)
                );
              }, 0)}
            </div>
          </div>

          {seasonRank?.data?.rank && (
            <div className={Styles.rank}>
              <h6>Rank</h6>
              <div className={Styles.seasonRank}>
                {seasonRank?.data?.rank ? seasonRank.data.rank : "no"}
              </div>
            </div>
          )}

          <div className={Styles.seasonEnds}>
            <div className={Styles.seasonEndsTitle}>Season ends:</div>
            {Object.keys(seasonTimeLeft).length > 0 ? (
              <div>
                {seasonTimeLeft.days}
                {"d "}
                {seasonTimeLeft.hours}
                {"h "}
                {seasonTimeLeft.minutes}
                {"m "}
                {seasonTimeLeft.seconds}
                {"s"}
              </div>
            ) : Object.keys(seasonTimeLeft).length === 0 && data.endDate ? (
              <div>Calculating...</div>
            ) : (
              <div>The season has ended.</div>
            )}
          </div>
        </div>

        {data.seasonSegments.map((segment, index) => (
          <div
            key={segment.id}
            className={`${Styles.week} ${
              new Date(segment.startDate) <= new Date()
                ? Styles.active
                : Styles.inactive
            }`}
          >
            <div className={Styles.visibleDetails}>
              <div className={Styles.weekTitle}>
                <h4>Week {segment.segmentNumber}</h4>
              </div>

              {new Date(segment.startDate) <= new Date() ? (
                // segment has started or is in the past...
                <div className={Styles.weekDetails}>
                  <div className={Styles.challenges}>
                    <div
                      className={`${Styles.challenge} ${
                        segment.seasonChallenges.find(
                          (challenge) =>
                            challenge.challengeType === "racer" &&
                            challenge.seasonChallengeProgresses.length > 0
                        )
                          ? Styles.completed
                          : Styles.notCompleted
                      }`}
                    >
                      <Racer />
                    </div>

                    <div
                      className={`${Styles.challenge} ${
                        segment.seasonChallenges.find(
                          (challenge) =>
                            challenge.challengeType === "speedCage" &&
                            challenge.seasonChallengeProgresses.length > 0
                        )
                          ? Styles.completed
                          : Styles.notCompleted
                      }`}
                    >
                      <SpeedCage />
                    </div>
                  </div>

                  <div className={Styles.streaks}>
                    {seasonSegments.map((segment) => (
                      <span
                        key={segment.segmentNumber}
                        className={
                          segment.seasonSegmentProgresses[0]?.streakCompleted &&
                          segment.segmentNumber <= index + 1
                            ? Styles.completed
                            : Styles.notCompleted
                        }
                      ></span>
                    ))}
                  </div>

                  <div className={Styles.addedPoints}>
                    {segment?.seasonSegmentProgresses[0]?.totalPoints
                      ? `+ ${segment.seasonSegmentProgresses[0].totalPoints}`
                      : "+ 0"}
                  </div>

                  <div className={Styles.totalPoints}>
                    {seasonSegments
                      .slice(0, index + 1)
                      .reduce((total, segment) => {
                        return (
                          total +
                          (segment.seasonSegmentProgresses[0]?.totalPoints || 0)
                        );
                      }, 0)}
                  </div>
                </div>
              ) : (
                // segment has not started yet...
                <div className={Styles.weekDetails}>
                  <div className={Styles.challenges}>
                    <div
                      className={`${Styles.challenge} ${Styles.notCompleted}`}
                    >
                      <Racer />
                    </div>
                    <div
                      className={`${Styles.challenge} ${Styles.notCompleted}`}
                    >
                      <SpeedCage />
                    </div>
                  </div>

                  <div className={Styles.streaks}>
                    <span className={Styles.notCompleted}></span>
                    <span className={Styles.notCompleted}></span>
                    <span className={Styles.notCompleted}></span>
                    <span className={Styles.notCompleted}></span>
                  </div>

                  <div className={Styles.upcomingWeekDateTime}>
                    {Object.keys(calculateTimeLeft(segment.startDate)).length >
                    0 ? (
                      <div>
                        {calculateTimeLeft(segment.startDate).days}
                        {"d "}
                        {calculateTimeLeft(segment.startDate).hours}
                        {"h "}
                        {calculateTimeLeft(segment.startDate).minutes}
                        {"m "}
                        {calculateTimeLeft(segment.startDate).seconds}
                        {"s"}
                      </div>
                    ) : (
                      <div>Calculating...</div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {new Date(segment.startDate) <= new Date() && (
              <div className={Styles.weekHiddenDetails}>
                <div className={Styles.challengeStatus}>
                  <div
                    className={`${Styles.challengeInfo} ${
                      segment.seasonChallenges.find(
                        (challenge) =>
                          challenge.challengeType === "racer" &&
                          challenge.seasonChallengeProgresses.length > 0
                      )
                        ? Styles.completed
                        : Styles.notCompleted
                    }`}
                  >
                    {segment.seasonChallenges.find(
                      (challenge) =>
                        challenge.challengeType === "racer" &&
                        challenge.seasonChallengeProgresses.length > 0
                    ) ? (
                      <MdCheckBox />
                    ) : (
                      <MdCheckBoxOutlineBlank />
                    )}{" "}
                    Complete Racer Challenge
                  </div>
                  <div
                    className={`${Styles.addedPoints} ${
                      segment.seasonChallenges.find(
                        (challenge) =>
                          challenge.challengeType === "racer" &&
                          challenge.seasonChallengeProgresses.length > 0
                      )
                        ? Styles.active
                        : ""
                    }`}
                  >
                    + 10
                  </div>
                </div>

                <div className={Styles.challengeStatus}>
                  <div
                    className={`${Styles.challengeInfo} ${
                      segment.seasonChallenges.find(
                        (challenge) =>
                          challenge.challengeType === "speedCage" &&
                          challenge.seasonChallengeProgresses.length > 0
                      )
                        ? Styles.completed
                        : Styles.notCompleted
                    }`}
                  >
                    {segment.seasonChallenges.find(
                      (challenge) =>
                        challenge.challengeType === "speedCage" &&
                        challenge.seasonChallengeProgresses.length > 0
                    ) ? (
                      <MdCheckBox />
                    ) : (
                      <MdCheckBoxOutlineBlank />
                    )}{" "}
                    Complete SpeedCage Challenge
                  </div>
                  <div
                    className={`${Styles.addedPoints} ${
                      segment.seasonChallenges.find(
                        (challenge) =>
                          challenge.challengeType === "speedCage" &&
                          challenge.seasonChallengeProgresses.length > 0
                      )
                        ? Styles.active
                        : ""
                    }`}
                  >
                    + 10
                  </div>
                </div>

                <div className={Styles.challengeStatus}>
                  <div
                    className={`${Styles.challengeInfo} ${
                      segment.seasonSegmentProgresses[0]?.streakCompleted
                        ? Styles.completed
                        : Styles.notCompleted
                    }`}
                  >
                    {segment.seasonSegmentProgresses[0]?.streakCompleted ? (
                      <MdCheckBox />
                    ) : (
                      <MdCheckBoxOutlineBlank />
                    )}{" "}
                    Complete Both Challenges
                  </div>
                  <div className={Styles.addedPoints}>
                    {segment?.seasonSegmentProgresses[0]?.streakPoints ? (
                      <div className={Styles.addedStreakPoints}>
                        <span
                          className={`${
                            segment.seasonSegmentProgresses[0].streakPoints ===
                            10
                              ? Styles.active
                              : ""
                          }`}
                        >
                          + 10
                        </span>{" "}
                        /
                        <span
                          className={`${
                            segment.seasonSegmentProgresses[0].streakPoints ===
                            20
                              ? Styles.active
                              : ""
                          }`}
                        >
                          + 20
                        </span>{" "}
                        /
                        <span
                          className={`${
                            segment.seasonSegmentProgresses[0].streakPoints ===
                            35
                              ? Styles.active
                              : ""
                          }`}
                        >
                          + 35
                        </span>{" "}
                        /
                        <span
                          className={`${
                            segment.seasonSegmentProgresses[0].streakPoints ===
                            50
                              ? Styles.active
                              : ""
                          }`}
                        >
                          + 50
                        </span>
                      </div>
                    ) : (
                      <div className={Styles.addedStreakPoints}>
                        <span>+ 10</span> /<span>+ 20</span> /<span>+ 35</span>{" "}
                        /<span>+ 50</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className={Styles.addedRankingPoints}>
                  <div className={Styles.title}>
                    <h4>SpeedCage Rank</h4>
                  </div>

                  {
                    // check if this segment is the segment, that is currently ongoing
                    segment.segmentNumber === ongoingSegment.segmentNumber ? (
                      <div className={Styles.liveRank}>Live Rank</div>
                    ) : (
                      <div></div>
                    )
                  }

                  {segment.seasonChallenges?.find(
                    (challenge) =>
                      challenge.challengeType === "speedCage" &&
                      challenge.seasonChallengeProgresses.length > 0
                  )?.seasonChallengeProgresses[0]?.rankingPoints ? (
                    <div className={Styles.challengeRank}>
                      {segment.seasonChallenges?.find(
                        (challenge) =>
                          challenge.challengeType === "speedCage" &&
                          challenge.seasonChallengeProgresses.length > 0
                      )?.seasonChallengeProgresses[0]?.seasonChallengeId ? (
                        <ChallengeRank
                          seasonChallengeId={
                            segment.seasonChallenges?.find(
                              (challenge) =>
                                challenge.challengeType === "speedCage" &&
                                challenge.seasonChallengeProgresses.length > 0
                            )?.seasonChallengeProgresses[0]?.seasonChallengeId
                          }
                        />
                      ) : null}
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div
                    className={`${Styles.addedPoints} ${
                      segment.seasonChallenges?.find(
                        (challenge) =>
                          challenge.challengeType === "speedCage" &&
                          challenge.seasonChallengeProgresses.length > 0
                      )?.seasonChallengeProgresses[0]?.rankingPoints
                        ? Styles.active
                        : ""
                    }`}
                  >
                    {segment.seasonChallenges?.find(
                      (challenge) =>
                        challenge.challengeType === "speedCage" &&
                        challenge.seasonChallengeProgresses.length > 0
                    )?.seasonChallengeProgresses[0]?.rankingPoints || "–"}
                  </div>
                </div>

                <div className={Styles.addedRankingPoints}>
                  <div className={Styles.title}>
                    <h4>Racer Rank</h4>
                  </div>

                  {
                    // check if this segment is the segment, that is currently ongoing
                    segment.segmentNumber === ongoingSegment.segmentNumber ? (
                      <div className={Styles.liveRank}>Live Rank</div>
                    ) : (
                      <div></div>
                    )
                  }

                  {segment.seasonChallenges?.find(
                    (challenge) =>
                      challenge.challengeType === "racer" &&
                      challenge.seasonChallengeProgresses.length > 0
                  )?.seasonChallengeProgresses[0]?.rankingPoints ? (
                    <div className={Styles.challengeRank}>
                      {segment.seasonChallenges?.find(
                        (challenge) =>
                          challenge.challengeType === "racer" &&
                          challenge.seasonChallengeProgresses.length > 0
                      )?.seasonChallengeProgresses[0]?.seasonChallengeId ? (
                        <ChallengeRank
                          seasonChallengeId={
                            segment.seasonChallenges?.find(
                              (challenge) =>
                                challenge.challengeType === "racer" &&
                                challenge.seasonChallengeProgresses.length > 0
                            )?.seasonChallengeProgresses[0]?.seasonChallengeId
                          }
                        />
                      ) : null}
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div
                    className={`${Styles.addedPoints} ${
                      segment.seasonChallenges?.find(
                        (challenge) =>
                          challenge.challengeType === "racer" &&
                          challenge.seasonChallengeProgresses.length > 0
                      )?.seasonChallengeProgresses[0]?.rankingPoints
                        ? Styles.active
                        : ""
                    }`}
                  >
                    {segment.seasonChallenges?.find(
                      (challenge) =>
                        challenge.challengeType === "racer" &&
                        challenge.seasonChallengeProgresses.length > 0
                    )?.seasonChallengeProgresses[0]?.rankingPoints || "–"}
                  </div>
                </div>

                {/* <div className={Styles.addedRankingPoints}>
                  <div className={Styles.title}>
                    <h4>Total Points</h4>
                  </div>

                  <div></div>
                  <div className={Styles.addedPoints}>+ {segment.seasonSegmentProgresses[0]?.totalPoints || 0}</div>
                  <div className={Styles.addedPoints}>
                  {seasonSegments
                      .slice(0, index + 1)
                      .reduce((total, segment) => {
                        return (
                          total +
                          (segment.seasonSegmentProgresses[0]?.totalPoints || 0)
                        );
                      }, 0)}</div>
                </div> */}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* <pre style={{ fontSize: 7 }}>{JSON.stringify(data, null, 4)}</pre> */}
    </Layout>
  );
};

export default Season;
