import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery } from "react-query";

import ROOT_URL from "../../Config/rootUrl";

const ChallengeRank = ({ seasonChallengeId }) => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();

  const challengeRank = useQuery(
    ["getChallengeRank", seasonChallengeId],
    async () => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/season/seasonchallengeprogress/${seasonChallengeId}/user-rank`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 404) {
        return { message: "No ranking found." };
      }

      const responseJson = await response.json();
      if (responseJson?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      enabled: !!seasonChallengeId,
    }
  );

  if(challengeRank.isLoading || !challengeRank.data) return <div>Loading...</div>
  if(challengeRank.isError) return <div>Error</div>

  return (
    <div>Rank {challengeRank.data.rank}</div>
  );
};

export default ChallengeRank;
